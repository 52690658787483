import React from 'react'

import { Box, Skeleton } from '@mui/material'
import { SlideInStaggered } from '../components/transitions/SlideInStaggered'

const ResultItem = ({ result }) => {
	return (
		<Box sx={{ px: 2, py: 1 }}>
			<Box sx={{ my: 0.75 }}>
				<Skeleton variant={'text'} height={30} width={350} />
				<Skeleton variant={'text'} height={20} width={600} />
			</Box>
		</Box>
	)
}

const SearchResultSkeleton = ({ result, ...props }) => {
	return (
		<Box sx={{ py: 1 }} {...props}>
			<SlideInStaggered>
				{Array.from(new Array(5)).map((_, index) => (
					<ResultItem key={index} />
				))}
			</SlideInStaggered>
		</Box>
	)
}

export default SearchResultSkeleton
