import React from 'react'

import { List, ListItem, Paper, Typography } from '@mui/material'

import Link from '../components/Link'

const ProductItem = ({ descr, isLastItem }) => {
	return (
		<ListItem divider={!isLastItem}>
			<Typography color={'text.secondary'} variant={'caption'}>
				{descr}
			</Typography>
		</ListItem>
	)
}

const SearchResultProducts = ({ products }) => {
	const [showAll, setShowAll] = React.useState(false)

	return products.length > 0 ? (
		<List
			component={Paper}
			dense
			elevation={0}
			sx={{
				borderRadius: 0.5,
				display: 'inline-flex',
				flexDirection: 'column',
			}}
		>
			<ListItem divider>
				<Typography color={'text.secondary'} variant={'caption'}>
					Products
				</Typography>
			</ListItem>
			{products
				.slice(0, showAll ? products.length : 6)
				.map((product, index) => (
					<ProductItem
						key={product.id}
						isLastItem={index === products.length - 1}
						{...product}
					/>
				))}

			{products.length > 6 ? (
				showAll ? (
					<ListItem>
						<Link
							onClick={() => setShowAll(false)}
							variant={'caption'}
						>
							Show less
						</Link>
					</ListItem>
				) : (
					<ListItem>
						<Link
							onClick={() => setShowAll(true)}
							variant={'caption'}
						>
							Show more
						</Link>
					</ListItem>
				)
			) : null}
		</List>
	) : null
}

export default SearchResultProducts
