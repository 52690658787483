import React from 'react'

import { List } from '@mui/material'

import { useSearchResults } from './searchContext'
import SearchResultItem from './SearchResultItem'
import SearchResultsSkeleton from './SearchResultsSkeleton'

export const SearchResults = () => {
	const { data: results, isLoading } = useSearchResults()

	return isLoading ? (
		<SearchResultsSkeleton />
	) : (
		<List>
			{results.map(result => (
				<SearchResultItem key={result['h_tinymce']} result={result} />
			))}
		</List>
	)
}

export default SearchResults
