/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Grid} from '@mui/material';
import {SearchInput, SearchResults} from '../../supersupplier';
import supersupplierLogo from '../../supersupplier/assets/supersupplier-logo.png';
import {DefaultLayout as MDXLayout} from "../../components/DefaultLayout";
export {Head} from '../../components/DefaultLayout';
function _createMdxContent(props) {
  const {Block} = Object.assign({}, _provideComponents(), props.components);
  if (!Block) _missingMdxReference("Block", true);
  return React.createElement(Block, null, React.createElement(Grid, {
    container: true,
    spacing: 4,
    sx: {
      minHeight: 64 * 8
    }
  }, React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    md: 3
  }, React.createElement("img", {
    src: supersupplierLogo,
    alt: "SuperSupplier logo",
    style: {
      maxWidth: '384px',
      width: '100%'
    }
  })), React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 8,
    md: 9
  }, React.createElement(SearchInput), React.createElement(SearchResults))));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
