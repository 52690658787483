import React from 'react'

import { Box, ListItem, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import Link from '../components/Link'
import SearchResultProducts from './SearchResultProducts'
import badgeIcons from './assets/badges-big.png'

const M2NORTH_DOMAIN = 'https://m2north.com'

const badges = {
	preferredsupplier: { offset: 0, color: '#FFD700' },
	green: { offset: -28, color: '#FFD700' },
	sellstobluechip: { offset: -55, color: '#FFD700' },
	profilecompletion: { offset: -83, color: '#FFD700' },
	'just-in-time': { offset: -111, color: '#FFD700' },
	bbbee: { offset: -139, color: '#FFD700' },
	b2b: { offset: -167, color: '#FFD700' },
	webstore: { offset: -194, color: '#FFD700' },
	products: { offset: -223, color: '#FFD700' },
	mining: { offset: -250, color: '#FFD700' },
}

const StyledBadge = styled('div')(({ theme }) => ({
	backgroundImage: `url(${badgeIcons})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	height: 30,
	width: 28,
}))

const StyledWrapper = styled('div')(({ theme }) => ({
	margin: theme.spacing(0.75, 0),
}))

const convertObjectToArray = items => {
	if (!items || items.length === 0) {
		return []
	}

	return Object.keys(items).reduce((acc, key) => {
		const item = items[key]
		return [...acc, { id: key, ...item }]
	}, [])
}

const LinkItem = ({ link, title }) => {
	const url = title === 'View Products' ? `${M2NORTH_DOMAIN}${link}` : link
	return (
		<Link to={url} variant={'caption'}>
			{title}
		</Link>
	)
}

const BadgeItem = ({ descr, numberofbadges, title, id }) => {
	return (
		<StyledBadge
			title={title}
			style={{ backgroundPosition: `${badges[id].offset}px 0` }}
		/>
	)
}

const SearchResultItem = ({ result }) => {
	const links = convertObjectToArray(result['links'])
	const badges = convertObjectToArray(result['badges'])
	const products = convertObjectToArray(result['products'])

	return (
		<ListItem>
			<StyledWrapper>
				<Stack direction={'row'} flexWrap={'wrap'}>
					<Link
						to={`${M2NORTH_DOMAIN}${result.profilelink}`}
						sx={{ display: 'block', fontSize: '1.25rem', mr: 2 }}
					>
						{result.descr}
					</Link>
					<Stack direction={'row'} spacing={0.5}>
						{badges.map(badge => (
							<BadgeItem key={badge.id} {...badge} />
						))}
					</Stack>
				</Stack>
				<Typography
					variant={'body2'}
					color={'text.secondary'}
					sx={{ fontSize: '0.875rem' }}
				>
					{result.reason}
				</Typography>

				{links.length > 0 ? (
					<Box
						variant={'caption'}
						color={'text.secondary'}
						sx={{ mt: 1 }}
					>
						{links.map(link => (
							<LinkItem key={link.id} {...link} />
						))}
					</Box>
				) : null}

				<Box sx={{ mt: 1 }}>
					<SearchResultProducts products={products} />
				</Box>
			</StyledWrapper>
		</ListItem>
	)
}

export default SearchResultItem
