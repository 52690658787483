import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'

import { ButtonBase, Icon, InputBase } from '@mui/material'
import { styled, ThemeProvider } from '@mui/material/styles'

import { lightTheme } from '../themes'
import { useSearchResults } from './searchContext'
import { noop } from '../utils'
import { useEffect } from 'react'

const StyledWrapper = styled('form')(({ theme }) => ({
	display: 'inline-flex',
	position: 'relative',
	maxWidth: theme.spacing(8 * 6),
	width: '100%',
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
	borderRadius: 9999,
	backgroundColor: theme.palette.background.paper,
	color: theme.palette.text.primary,
	height: theme.spacing(8),
	padding: theme.spacing(0, 4),
	width: '100%',

	'& ::placeholder': {
		opacity: 0.5,
	},
}))

const StyledButton = styled(ButtonBase)(({ theme }) => ({
	borderRadius: 9999,
	background: theme.palette.gradient.default,
	height: theme.spacing(7),
	position: 'absolute',
	right: theme.spacing(0.5),
	top: '50%',
	transform: 'translateY(-50%)',
	width: theme.spacing(7),
}))

export const SearchInput = ({ onSubmit = noop }) => {
	const { searchText } = useSearchResults()
	const { getValues, handleSubmit, register, setValue } = useForm()

	useEffect(() => {
		const currentValue = getValues('searchQuery')
		if (!currentValue !== searchText) {
			setValue('searchQuery', searchText)
		}
	}, [getValues, searchText, setValue])

	const submit = form => {
		navigate(`/supersupplier/search/?q=${form.searchQuery}`)
	}

	return (
		<ThemeProvider theme={lightTheme}>
			<StyledWrapper onSubmit={handleSubmit(submit)}>
				<StyledInput
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					fullWidth
					defaultValue={searchText}
					placeholder={'Find brilliant B2B suppliers'}
					{...register('searchQuery')}
				/>
				<StyledButton type={'submit'}>
					<Icon style={{ fontSize: 28 }}>search</Icon>
				</StyledButton>
			</StyledWrapper>
		</ThemeProvider>
	)
}

export default SearchInput
